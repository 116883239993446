<template>
    <div>
      <div class="main-loader" v-if="isLoading"></div>
      <div class="agents-page" v-else>
        <b-container>
          <!-- Swiper Component for Agent Categories -->
          <b-row class="swiper-container mt-1">
            <!-- <swiper :options="swiperOptions" class="swiper-container mt-1">
              <swiper-slide v-for="(tab, index) in tabs" :key="index" class="ss-width">
                <b-button 
                  :variant="isSelected(tab.category) ? 'dark' : 'outline-secondary'" 
                  @click="toggleCategory(tab.category)" 
                  class="tab-button">
                  {{ tab.category }}
                </b-button>
              </swiper-slide>
            </swiper> -->
          </b-row>
  
          <!-- Agent Cards -->
          <b-row v-if="filteredAgents.length">
            <agent-card 
              v-for="agent in paginatedAgents"
              :key="agent.agentId" 
              :agent="agent"
              @run-agent="runAgent(agent.agentId)"
              :showShareButton="false"
              :showUserExecutionCount="false" 
            />
          </b-row>
  
          <!-- No Data Found Message -->
          <div class="no-data mt-5 mb-5" v-else>
            <div class="mx-auto text-center">
              <h2>No agents found. Explore and add agents to this page.</h2>
            </div>
          </div>
  
          <!-- Infinite Scroll Trigger -->
          <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
        </b-container>
      </div>
    </div>
  </template>
  
  <script>
  import agentCard from './agentCard.vue'; // Your agentCard component
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  import axios from 'axios';
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'AgentsPage',
    components: {
      Swiper,
      SwiperSlide,
      agentCard,
    },
    data() {
      return {
        isLoading: true,
        tabs: [], // Dynamic categories from agents
        selectedCategories: [], // Array to store selected categories
        agentsPerPage: 10, // Number of agents per page for pagination
        currentPage: 1,
        swiperOptions: {
          slidesPerView: 'auto',
          spaceBetween: 8,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1024: {
              slidesPerView: 'auto',
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 'auto',
              spaceBetween: 6,
            },
            640: {
              slidesPerView: 'auto',
              spaceBetween: 4,
            },
            320: {
              slidesPerView: 'auto',
              spaceBetween: 2,
            },
          },
        },
      };
    },
    computed: {
      ...mapGetters('app', ['allAgents']),
      
      filteredAgents() {
        if (this.selectedCategories.length > 0) {
          return this.allAgents.filter(agent => {
            const agentCategories = Array.isArray(agent.category) 
              ? agent.category 
              : agent.category.split(',').map(cat => cat.trim());
            return agentCategories.some(cat => this.selectedCategories.includes(cat));
          });
        }
        return this.allAgents;
      },
      
      paginatedAgents() {
        return this.filteredAgents.slice(0, this.agentsPerPage * this.currentPage);
      },
  
      hasMoreAgents() {
        return this.paginatedAgents.length < this.filteredAgents.length;
      },
    },
    async created() {
      await this.fetchAgents(); // Await the fetching of agents
      this.generateTabs(); // Create the tabs dynamically based on agents
      this.isLoading = false; // Loading completed
    },
    mounted() {
      this.initializeIntersectionObserver(); // Set up infinite scroll
    },
    methods: {
      ...mapActions('app', ['fetchAgents']),
      
      runAgent(agentId) {
        this.$router.push(`/agents/${agentId}`);
      },
      
      generateTabs() {
        const categories = new Set();
        this.allAgents.forEach(agent => {
          // Check if category is an array, otherwise treat as a string and split by comma
          const agentCategories = Array.isArray(agent.category) 
            ? agent.category 
            : agent.category.split(',').map(cat => cat.trim());
          
          agentCategories.forEach(cat => categories.add(cat));
        });
  
        this.tabs = Array.from(categories).map(category => ({
          category,
          active: this.isSelected(category),
        }));
  
        // Only set the first category if there are tabs
        if (this.tabs.length > 0) {
          this.selectedCategories = [this.tabs[0].category]; // Select the first category by default
        }
      },
      
      toggleCategory(category) {
        if (this.isSelected(category)) {
          this.selectedCategories = this.selectedCategories.filter(selected => selected !== category);
        } else {
          this.selectedCategories.push(category);
        }
        this.currentPage = 1; // Reset pagination when the category changes
      },
      
      isSelected(category) {
        return this.selectedCategories.includes(category);
      },
      
      initializeIntersectionObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        };
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.loadMoreAgents();
            }
          });
        }, options);
        if (this.$refs.loadMoreTrigger) {
          this.observer.observe(this.$refs.loadMoreTrigger);
        }
      },
      
      loadMoreAgents() {
        if (this.hasMoreAgents) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 15px;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    margin: 0 5px;
  }
  
  .infinite-scroll-trigger {
    width: 100%;
    height: 50px;
  }
  /* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
  color: white;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}
.agents-page {
  margin-left: auto;
  margin-right: auto;
}
.b-container {
  padding: 0 20px; /* Padding for the container to align content nicely */
}

.no-data {
  text-align: center;
  padding: 20px;
}

  </style>
  